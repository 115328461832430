<template>
  <v-container fluid>
    <v-layout>
      <v-flex>
        <v-card>
          <v-card-title>
            <v-text-field v-model="search"
                          append-icon="mdi-magnify"
                          label="Search"
                          single-line
                          hide-details />
          </v-card-title>
          <v-data-table :headers="headers"
                        :items="filteredTenants"
                        :loading="loading"
                        multi-sort
                        class="elevation-1">
            <template v-slot:item.enableSendingEmails="{ item }">
              <v-icon>
                {{
                  item.enableSendingEmails
                    ? 'mdi-check-circle-outline'
                    : 'mdi-close-circle-outline'
                }}
              </v-icon>
            </template>

            <template v-slot:item.action="{ item }">
              <v-btn :to="`/edit/${item.uuid}`"
                     text
                     fab
                     small>
                <v-icon>mdi-circle-edit-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Home',
  data: () => ({
    search: '',
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Display ID', value: 'displayId' },
      { text: 'Uuid', value: 'uuid' },
      { text: 'Locale', value: 'locale' },
      { text: 'Web Url', value: 'webUrl' },
      { text: 'Emails enabled', value: 'enableSendingEmails', align: 'center' },
      { text: 'Edit', value: 'action', align: 'right' },
    ],
  }),
  computed: {
    ...mapGetters(['tenants']),
    filteredTenants() {
      const regx = new RegExp(this.search, 'i');
      return this.tenants && this.tenants.filter((tenant) => regx.test(tenant.name));
    },
    loading() {
      return this.tenants && !this.tenants.length;
    },
  },
  created() {
    this.$store.dispatch('loadTenants');
  },
};
</script>
